import React from "react";
import "./loader.css";
import { TailSpin } from "react-loader-spinner";
const Loader = () => {
  return (

      <TailSpin
        visible={true}
        height="30"
        width="30"
        color="#63223d"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
      />

  );
};

export default Loader;
