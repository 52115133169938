import React, { useEffect, useState } from "react";
import "../../../style.css"; // Import the CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const GoogleSheetData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterAddress, setFilterAddress] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000000); // Set an initial maximum price
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      // const sheetId = "1BbkuXudQN0XXlS_7gFy3SwkVVgZeH8YoMuK5xbmVfuk"; // Replace with your Google Sheet ID
      const sheetId = "1OxCKl74fSKwN7Po-WugVChnP8jM8CipQdkQ9DEZarW8"; // Replace with your Google Sheet ID
      const apiKey = "AIzaSyDlpDwcuxN6rFYEHyFTmxT3CAyaKsG2awM"; // API key from environment variables
      // const range = "hub!A1:N38";
      const range = "Sheet1";
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        console.log("Response: ", result.values);
        setData(result.values || []);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh", }}><p className="d-inline-block fa-2x">Loading...</p></div>;
  if (error) return <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh", }}><p className="d-inline-block fa-2x text-danger">Error: {error}</p></div>;

  const rows = data.slice(1); // The remaining rows contain the actual data

  const handlePriceChange = (setter) => (e) => {
    // Remove leading zeros and ensure valid number
    const value = e.target.value.replace(/^0+/, "") || "0";
    setter(value);
  };

  const filteredRows = rows.filter((row) => {
    const title = row[3]?.toLowerCase() || "";
    const address = row[4]?.toLowerCase() || "";
    const priceString = row[2] && typeof row[2] === "string" ? row[2] : "";
    const cleanedPriceString = priceString.replace(/[^0-9]/g, ""); // Remove non-numeric characters including currency symbol and spaces
    const price = parseInt(cleanedPriceString, 10) || 0; // Parse the cleaned string

    const beds = parseInt(row[6]) || 0;
    const baths = parseInt(row[7]) || 0;

    const searchText = filterAddress.toLowerCase();
    console.log("Title: ", title);
    return (
      (title.includes(searchText) || address.includes(searchText)) &&
      price >= minPrice &&
      price <= maxPrice &&
      beds >= bedrooms &&
      baths >= bathrooms
    );
  });


  return (
    <div className="page-container">
      <div className="search-bar-container">
        <input
          type="text"
          placeholder="Search by address..."
          value={filterAddress}
          onChange={(e) => setFilterAddress(e.target.value)}
          className="searchbar"
        />
      </div>

      {/*price sliders for max and min price plus buttons to beds and baths filter*/}
      <div className="filter">
        <div className="filter-item">
          <label style={{ fontWeight: "bold" }}>Min Price:</label>
          <input
            type="number"
            min="0"
            max="1000000"
            step="1000"
            value={minPrice}
            onChange={handlePriceChange(setMinPrice)}
            className="filter-button"
          />
        </div>
        <div className="filter-item">
          <label style={{ fontWeight: "bold" }}>Max Price:</label>
          <input
            type="number"
            min="0"
            max="1000000"
            step="1000"
            value={maxPrice}
            onChange={handlePriceChange(setMaxPrice)}
            className="filter-button"
          />
        </div>
        <div className="filter-item">
          <label style={{ fontWeight: "bold" }}>Bedrooms:</label>
          <input
            type="number"
            min="1"
            max="10"
            value={bedrooms}
            onChange={handlePriceChange(setBedrooms)}
            className="filter-button"
          />
        </div>
        <div className="filter-item">
          <label style={{ fontWeight: "bold" }}>Bathrooms:</label>
          <input
            type="number"
            min="1"
            max="10"
            value={bathrooms}
            onChange={handlePriceChange(setBathrooms)}
            className="filter-button"
          />
        </div>
      </div>


      {
        filteredRows.length > 0 ? (
          <div className="card-container">
            {
              filteredRows.map((row, index) => {
                const imageUrl = row[1];
                const price = row[2];
                const property_link = row[0];
                const title = row[3];
                const address = row[4];
                const bedrooms = row[5];
                const bathrooms = row[6];
                const cars = row[7];
                const size = row[8];
                const available = row[9];
                const description = row[10];
                return (
                  <div key={index} className="card">
                    <h4 className="price">{price}</h4>

                    <a href={`https://www.property24.com${property_link}`} target="_blank" rel="noopener noreferrer">
                      {imageUrl && (
                        <img
                          src={imageUrl}
                          alt="A detailed description of the opener content"
                          className="image"
                        />
                      )}
                    </a>

                    <div className="text-container">
                      {title && <h3 className="title">{title}</h3>}
                      {address && <h2 className="address">{address}</h2>}
                      <div className="d-flex align-items-center mt-2">
                        {
                          bedrooms &&
                          <div className="d-inline-flex align-items-center me-3">
                            <FontAwesomeIcon icon="fa-solid fa-bed" style={{ color: "#000000" }} />
                            <span className="bedroom mx-2">{bedrooms}</span>
                          </div>
                        }
                        {
                          bathrooms &&
                          <div className="d-inline-flex align-items-center me-3">
                            <FontAwesomeIcon icon="fa-solid fa-bath" style={{ color: "#000000" }} />
                            <span className="bedroom mx-2">{bathrooms}</span>
                          </div>
                        }
                        {
                          cars &&
                          <div className="d-inline-flex align-items-center me-3">
                            <FontAwesomeIcon icon="fa-solid fa-car" style={{ color: "#000000", }} />
                            <span className="bedroom mx-2">{cars}</span>
                          </div>
                        }
                        {
                          size &&
                          <div className="d-inline-flex align-items-center me-3">
                            <FontAwesomeIcon icon="fa-solid fa-arrow-down-up-across-line" style={{ color: "#000000", }} />
                            <span className="bedroom mx-2">{size}</span>
                          </div>
                        }

                      </div>
                      {description && <h6 className="description">{description}</h6>}
                      {available && <div className="available-div"><h6 className="available">Available: <span>{available}</span></h6></div>}
                    </div>
                  </div>
                );
              })}
          </div>
        ) :
          <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh", width: "100%" }}>
            <p className=" fa-2x">Not Found</p>
          </div>
      }


    </div>
  );
};

export default GoogleSheetData;
