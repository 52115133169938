import React from "react";

//layoutpages
import Default from "../layouts/dashboard/default";

import { DefaultRouter } from "./default-router";
import { Layout1Router } from "./layout1-router";

import Protected from "../views/ProtectedRoutes/Protected";



export const IndexRouters = [
  {
    path: "/",
    element:  <Protected Component={Default}  /> ,
    children: [...Layout1Router,...DefaultRouter ],
  },
];
