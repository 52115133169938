import React from "react";
import GoogleSheetData from "./googlesheet";


const Market1 = () => {

  return (
    <>
      <div id="content-page">
        <div className="mb-2 mt-5 mx-5">
          <h4>Property to Rent in Stellenbosch</h4>
        </div>
        <GoogleSheetData />
      </div>
    </>
  );
};
export default Market1;
