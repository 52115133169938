import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';

//router
import { BrowserRouter, createBrowserRouter, RouterProvider } from 'react-router-dom'

//store

import { Provider } from 'react-redux';
//reducer
import { store } from './store'

import { IndexRouters } from './router';
import { SimpleRouter } from './router/simple-router';
import { ChatRouter } from './router/chat-router';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBed, faBath, faCar, faArrowDownUpAcrossLine } from "@fortawesome/free-solid-svg-icons";

library.add(faBed, faBath, faCar, faArrowDownUpAcrossLine);

const router = createBrowserRouter([

  ...SimpleRouter,
  ...IndexRouters,
  ...ChatRouter
], { basename: process.env.PUBLIC_URL })
ReactDOM.createRoot(document.getElementById('root')).render(
  //  <BrowserRouter>
  <StrictMode>
    <Provider store={store}>
      <App>
        <RouterProvider router={router}>
        </RouterProvider>
      </App>
    </Provider>
  </StrictMode>
  //  </BrowserRouter>
)
// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter basename={process.env.PUBLIC_URL}>
//     <Provider store={store}>
//             <App />
//         </Provider>
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
