// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBpUrTMfgvbb8DKG3gpOHT2eVdwKNM0BUo",
    authDomain: "stellie-stay.firebaseapp.com",
    databaseURL: "https://stellie-stay-default-rtdb.firebaseio.com",
    projectId: "stellie-stay",
    storageBucket: "stellie-stay.firebasestorage.app",
    messagingSenderId: "371892492691",
    appId: "1:371892492691:web:d1468b347c12f543aff066",
    measurementId: "G-W64G21XLWB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getDatabase(app);
