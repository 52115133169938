import React from "react";
import ChatLayout from "../layouts/dashboard/chat-layout";
import Chat from "../views/dashboard/app/chat";
import NewChat from "../views/dashboard/app/NewChat";

export const ChatRouter = [
  {
    path: "chat",
    element: <NewChat />,
    // children: [
    //   {
    //     path: "index",
    //     element: <Chat />,
    //   },
    // ],
  },
];
