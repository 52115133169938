import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
} from "react-bootstrap";


import { auth, db } from "../../../firebase/firebaseConfig";
import { ref, get, update } from "firebase/database";
import { IMAGES } from "../../../utilities/images";

const UserProfileEdit = () => {
  const [name, setName] = useState('');
  const [degreeOfStudy, setDegreeOfStudy] = useState("");
  const [hometown, setHometown] = useState("");
  const [smoker, setSmoker] = useState('');
  const [alcohol, setAlcohol] = useState('');
  const [cleanlinessLevel, setCleanlinessLevel] = useState("");
  const [social, setSocial] = useState("");
  const [roomType, setRoomType] = useState("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState('');
  const [profileSuccess, setProfileSuccess] = useState("");
  const [profileError, setProfileError] = useState('');
  const [bio, setBio] = useState('');
  const [OldPassword, setOldPassword] = useState("");
  const [NewPassword, setNewPassword] = useState("");
  const [VerifyPassword, setVerifyPassword] = useState("");

  useEffect(() => {

    const currentUser = auth.currentUser;

    if (currentUser) {
      const userRef = ref(db, `users/${currentUser.uid}`);

      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.val();
            setName(userData?.name);
            setGender(userData?.gender);
            setBirthDate(userData?.birthDate);
            setCleanlinessLevel(userData?.cleanlinessLevel);
            setSmoker(userData?.smoker);
            setAlcohol(userData?.alcohol);
            setHometown(userData?.hometown);
            setDegreeOfStudy(userData?.degreeOfStudy);
            setRoomType(userData?.roomType);
            setSocial(userData?.social);
            setBio(userData?.bio);
            console.log(userData);
          } else {
            console.error("User data not found in the database.");
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, []);

  useEffect(() => {
    console.log(bio);
  }, [bio])

  const handleFileChange = (e) => {

  };

  const editProfile = async (e) => {
    e.preventDefault();
    const currentUser = auth.currentUser;

    if (currentUser) {
      const userRef = ref(db, `users/${currentUser.uid}`);

      try {
        setIsSubmitted(true);
        setProfileError('');

        // Update user data in Firebase
        await update(userRef, {
          name: name,
          degreeOfStudy: degreeOfStudy,
          hometown: hometown,
          smoker: smoker,
          alcohol: alcohol,
          cleanlinessLevel: cleanlinessLevel,
          social: social,
          roomType: roomType,
          gender: gender,
          birthDate: birthDate,
          bio: bio,
        });

        setProfileSuccess("Profile updated successfully!");
      } catch (error) {
        console.error("Error updating profile:", error);
        setProfileError("Failed to update profile. Please try again.");
      } finally {
        setIsSubmitted(false);
      }
    }
  };


  const changePassword = async (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div className="content-inner">
        <Container>
          <Tab.Container defaultActiveKey="first">
            <Row>
              <Col lg="12">
                <Card>
                  <Card.Body className="p-0">
                    <div>
                      <Nav
                        as="ul"
                        variant="pills"
                        className="iq-edit-profile row mb-0 align-items-center justify-content-center"
                      >
                        <Nav.Item as="li" className="col-md-6 p-0">
                          <Nav.Link eventKey="first" role="button">
                            Personal Information
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="col-md-6 p-0">
                          <Nav.Link eventKey="second" role="button">
                            Change Password
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                {/* <div className="iq-edit-list-data"> */}
                <Tab.Content>
                  <Tab.Pane eventKey="first" className="fade show">
                    <Card>
                      <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                          <h4 className="card-title">Personal Information</h4>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Form>
                          <Form.Group className="form-group align-items-center">
                            <Col md="12">
                              <div className="profile-img-edit">
                                <img
                                  className="profile-pic"
                                  src={IMAGES.avatar}
                                  alt="profile-pic"
                                />
                                <label htmlFor="upload">
                                  <div className="p-image d-flex align-items-center justify-content-center">
                                    <span className="material-symbols-outlined">
                                      edit
                                    </span>
                                    <input
                                      className="file-upload"
                                      type="file"
                                      accept="image/*"
                                      id="upload"
                                      multiple={false}
                                      onChange={handleFileChange}
                                    />
                                  </div>
                                </label>
                              </div>
                            </Col>
                          </Form.Group>
                          {profileSuccess && (
                            <div className="alert alert-success mt-2" role="alert" >
                              {profileSuccess}
                            </div>
                          )
                          }
                          <Row className="align-items-center">
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label
                                htmlFor="fname"
                                className="form-label"
                              >
                                Name:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="form-control"
                                id="name"
                                placeholder="Jan"
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label htmlFor="dob" className="form-label">
                                Date Of Birth:
                              </Form.Label>
                              <Form.Control
                                className="form-control"
                                value={birthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                                id="birthDate"
                                type="date"
                                placeholder="1984-01-24"
                              />
                            </Form.Group>
                            {/* Gender */}
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label w-100">Gender:</Form.Label>
                              <Form.Check
                                type="radio"
                                label="Male"
                                value="Male"
                                checked={gender === 'Male'}
                                onChange={(e) => setGender(e.target.value)}
                                inline
                                required
                              />
                              <Form.Check
                                type="radio"
                                label="Female"
                                value="Female"
                                checked={gender === 'Female'}
                                onChange={(e) => setGender(e.target.value)}
                                inline
                                required
                              />
                            </Form.Group>

                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Current Degree of Study:
                              </Form.Label>
                              <Form.Control
                                className="form-control"
                                id="dob"
                                type="text"
                                placeholder="Current Degree of Study"
                                value={degreeOfStudy}
                                onChange={(e) => setDegreeOfStudy(e.target.value)}
                              />
                            </Form.Group>

                            {/* Smoker */}
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label w-100">
                                Smoker:
                              </Form.Label>
                              <Form.Check
                                type="radio"
                                label="Yes"
                                value="Yes"
                                checked={smoker === 'Yes'}
                                onChange={(e) => setSmoker(e.target.value)}
                                inline
                              />
                              <Form.Check
                                type="radio"
                                label="No"
                                value="No"
                                checked={smoker === 'No'}
                                onChange={(e) => setSmoker(e.target.value)}
                                inline
                              />
                            </Form.Group>
                            {/* Alcohol */}
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label w-100">
                                Alcohol:
                              </Form.Label>
                              <Form.Check
                                type="radio"
                                label="Yes"
                                value="Yes"
                                checked={alcohol === 'Yes'}
                                onChange={(e) => setAlcohol(e.target.value)}
                                inline
                              />
                              <Form.Check
                                type="radio"
                                label="No"
                                value="No"
                                checked={alcohol === 'No'}
                                onChange={(e) => setAlcohol(e.target.value)}
                                inline
                              />
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <Form.Label className="form-label">
                                Hometown:
                              </Form.Label>
                              <Form.Control
                                className="form-control"
                                type="text"
                                placeholder="Hometown"
                                value={hometown}
                                onChange={(e) => setHometown(e.target.value)}
                              />
                            </Form.Group>
                            {/* Cleanliness Level */}
                            <Form.Group className="form-group text-start col-sm-6">
                              <Form.Label className="form-label">Cleanliness Level:</Form.Label>
                              <Form.Control
                                as="select"
                                className="form-control mb-0"
                                value={cleanlinessLevel}
                                required
                                onChange={(e) => setCleanlinessLevel(e.target.value)}
                              >
                                <option disabled>Select Cleanliness Level</option>
                                <option value="Neat">Neat</option>
                                <option value="Relaxed">Relaxed</option>
                                <option value="Working on it">Working on it</option>
                              </Form.Control>
                            </Form.Group>

                            {/* Social */}
                            <Form.Group className="form-group text-start col-sm-6">
                              <Form.Label className="form-label">Social:</Form.Label>
                              <Form.Control
                                as="select"
                                className="form-control mb-0"
                                value={social}
                                required
                                onChange={(e) => setSocial(e.target.value)}
                              >
                                <option disabled>Select Social Type</option>
                                <option value="Extrovert">Extrovert</option>
                                <option value="Ambivert">Ambivert</option>
                                <option value="Introvert">Introvert</option>
                              </Form.Control>
                            </Form.Group>

                            {/* Room-type */}
                            <Form.Group className="form-group text-start col-sm-6">
                              <Form.Label className="form-label">Room Type:</Form.Label>
                              <Form.Control
                                as="select"
                                className="form-control mb-0"
                                value={roomType}
                                onChange={(e) => setRoomType(e.target.value)}
                              >
                                <option disabled>Select Room Type</option>
                                <option value="Shared">Shared</option>
                                <option value="Single-room">Single-room</option>
                                <option value="Either">Either</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-12">
                              <Form.Label className="form-label">
                                Bio:
                              </Form.Label>
                              <textarea
                                className="form-control"
                                value={bio}
                                onChange={(e) => setBio(e.target.value)}
                                rows={5}
                                style={{ lineHeight: "22px" }}
                              />
                            </Form.Group>
                          </Row>
                          {profileError && (
                            <div className="alert alert-danger mt-2" role="alert" >
                              {profileError}
                            </div>
                          )
                          }
                          <Button
                            onClick={editProfile}
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            Submit
                          </Button>
                          {/* <Button
                            type="reset"
                            variant=""
                            className="btn-danger-subtle"
                          >
                            Cancel
                          </Button> */}
                        </Form>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second" className="fade show">
                    <Card>
                      <Card.Header className="d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Change Password</h4>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Form>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="cpass" className="form-label">
                              Current Password:
                            </Form.Label>
                            <Form.Control
                              type="Password"
                              className="form-control"
                              id="cpass"
                              value={OldPassword}
                              onChange={(e) => setOldPassword(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="npass" className="form-label">
                              New Password:
                            </Form.Label>
                            <Form.Control
                              type="Password"
                              className="form-control"
                              id="npass"
                              value={NewPassword}
                              onChange={(e) => setNewPassword(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group className="form-group">
                            <Form.Label htmlFor="vpass" className="form-label">
                              Verify Password:
                            </Form.Label>
                            <Form.Control
                              type="Password"
                              className="form-control"
                              id="vpass"
                              value={VerifyPassword}
                              onChange={(e) =>
                                setVerifyPassword(e.target.value)
                              }
                            />
                          </Form.Group>
                          {changePasswordError && (
                            <div className="alert alert-danger mt-2" role="alert" >
                              {changePasswordError}
                            </div>
                          )
                          }
                          <Button
                            onClick={changePassword}
                            type="submit"
                            className="btn btn-primary me-2"
                          >
                            {isSubmitted ? "Submitting..." : "Submit"}
                          </Button>{" "}
                          <button
                            type="reset"
                            className="btn btn-danger-subtle"
                          >
                            Cancel
                          </button>
                        </Form>
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                </Tab.Content>
                {/* </div> */}
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
    </>
  );
};

export default UserProfileEdit;
