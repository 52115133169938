import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Collapse,
  ProgressBar,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import CreatePost from "../../components/create-post";

//image
import user1 from "../../assets/images/user/1.jpg";
import user01 from "../../assets/images/user/01.jpg";
import user2 from "../../assets/images/user/02.jpg";
import user3 from "../../assets/images/user/03.jpg";
import user4 from "../../assets/images/user/04.jpg";
import user13 from "../../assets/images/user/05.jpg";
import user14 from "../../assets/images/user/06.jpg";
import user15 from "../../assets/images/user/07.jpg";
import user16 from "../../assets/images/user/08.jpg";
import user5 from "../../assets/images/page-img/fun.webp";
import user6 from "../../assets/images/user/13.jpg";
import user7 from "../../assets/images/user/17.jpg";
import user8 from "../../assets/images/user/16.jpg";
import user9 from "../../assets/images/user/09.jpg";
import user10 from "../../assets/images/user/10.jpg";
import user11 from "../../assets/images/user/14.jpg";
import user12 from "../../assets/images/user/15.jpg";
import profileBgImg from "../../assets/images/page-img/profile-bg9.jpg";

import icon1 from "../../assets/images/icon/01.png";
import icon2 from "../../assets/images/icon/02.png";
import icon3 from "../../assets/images/icon/03.png";
import icon4 from "../../assets/images/icon/04.png";
import icon5 from "../../assets/images/icon/05.png";
import icon6 from "../../assets/images/icon/06.png";
import icon7 from "../../assets/images/icon/07.png";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import boyImg from "../../assets/images/page-img/boy.webp";
import busImg from "../../assets/images/page-img/bus.webp";
import img11 from "../../assets/images/page-img/fd.webp";
import mountain from "../../assets/images/page-img/mountain.webp";
import pizza from "../../assets/images/page-img/pizza.webp";
import bootstrapImg from "../../assets/images/icon/bootstrap-5.png";
import adsImg from "../../assets/images/page-img/ads.jpg";

// Story components
import Stories from "../../components/Stories";

// FsLightbox

// Share-offcanvas
import ShareOffcanvasNew from "../../components/ShareOffcanvasNew";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataAsync, selectData } from "../../store/setting/reducers";
import Post from "../../components/custom/Post";
import axios from "axios";


// import img from '../assets/images/user/1.jpg'

const Index = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [modalShow5, setModalShow5] = useState(false);

  const [loadContent, setLoadContent] = useState(true);
  const [userComment, setComment] = useState('')

  const [allPost, setPosts] = useState([])
  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });
  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  }

  useEffect(() => {
    function handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        setTimeout(() => {
          setLoadContent(false);
        }, 2000);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // stories
  // const stories = [
  //   {
  //     id: "yourstory",
  //     photo: user8,
  //     name: "Your Story",
  //     link: "https://ramon.codes",
  //     preview: [
  //       {
  //         storyid: "yourstory-1",
  //         type: "photo",
  //         length: 3,
  //         src: "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/1.jpg",
  //         storypreview:
  //           "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/1.jpg",
  //         link: "",
  //         linkText: "false",
  //         seen: "false",
  //       },
  //       {
  //         storyid: "yourstory-12",
  //         type: "video",
  //         length: 0,
  //         src: "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/2.mp4",
  //         storypreview:
  //           "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/2.jpg",
  //         link: "",
  //         linkText: "false",
  //         seen: "false",
  //       },
  //     ],
  //   },
  //   {
  //     id: "ali",
  //     photo: user1,
  //     name: "Ali",
  //     link: "",
  //     preview: [
  //       {
  //         storyid: "ali-1",
  //         type: "video",
  //         length: 0,
  //         src: "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/4.mp4",
  //         storypreview:
  //           "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/4.jpg",
  //         link: "",
  //         linkText: "false",
  //         seen: "false",
  //       },
  //       {
  //         storyid: "ali-2",
  //         type: "photo",
  //         length: 3,
  //         src: "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/5.jpg",
  //         storypreview:
  //           "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/5.jpg",
  //         link: "",
  //         linkText: "false",
  //         seen: "false",
  //       },

  //       {
  //         storyid: "ali-3",
  //         type: "photo",
  //         length: 3,
  //         src: "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/3.png",
  //         storypreview:
  //           "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/3.png",
  //         link: "https://ramon.codes",
  //         linkText: "Visit my Portfolio",
  //         seen: "false",
  //       },
  //     ],
  //   },
  //   {
  //     id: "ammy",
  //     photo: user12,
  //     name: "Ammy",
  //     link: "",
  //     preview: [
  //       {
  //         storyid: "ammy-1",
  //         type: "photo",
  //         length: 5,
  //         src: "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/6.jpg",
  //         storypreview:
  //           "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/6.jpg",
  //         link: "",
  //         linkText: "false",
  //         seen: "false",
  //       },
  //       {
  //         storyid: "ammy-2",
  //         type: "photo",
  //         length: 3,
  //         src: "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/7.jpg",
  //         storypreview:
  //           "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/7.jpg",
  //         link: "http://ladygaga.com",
  //         linkText: "false",
  //         seen: "false",
  //       },
  //     ],
  //   },
  //   {
  //     id: "roger-1",
  //     photo: user3,
  //     name: "Roger",
  //     link: "",
  //     preview: [
  //       {
  //         storyid: "roger-1",
  //         type: "photo",
  //         length: 5,
  //         src: "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/8.jpg",
  //         storypreview:
  //           "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/8.jpg",
  //         link: "",
  //         linkText: "false",
  //         seen: "false",
  //       },
  //     ],
  //   },
  //   {
  //     id: "justin",
  //     photo: user11,
  //     name: "Justin",
  //     link: "",
  //     preview: [
  //       {
  //         storyid: "justin-1",
  //         type: "photo",
  //         length: 10,
  //         src: "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/9.jpg",
  //         storypreview:
  //           "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/9.jpg",
  //         link: "",
  //         linkText: "false",
  //         seen: "false",
  //       },
  //     ],
  //   },
  //   {
  //     id: "sado",
  //     photo: user3,
  //     name: "Sado",
  //     link: "",
  //     preview: [
  //       {
  //         storyid: "sado-1",
  //         type: "photo",
  //         length: 10,
  //         src: "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/9.jpg",
  //         storypreview:
  //           "https://raw.githubusercontent.com/ramon82/assets/master/zuck.js/stories/9.jpg",
  //         link: "",
  //         linkText: "false",
  //         seen: "false",
  //       },
  //     ],
  //   },
  // ];
  const dispatch = useDispatch();
  const { data } = useSelector(selectData);

  const ApiLink = "https://stellie-stay-backend.vercel.app";
  useEffect(() => {
    // dispatch(fetchDataAsync(`${ApiLink}/post/getPost`)); // Replace with your API endpoint
    getAllPost()
  }, []);
  // const handleComment = (e) => {

  //   dispatch(comments(`${ApiLink}/post/add-comment/:postId`)); // Replace with your API endpoint
  // }

  // console.log("data",data)
  const token = JSON.parse(localStorage.getItem("user"))?.token;

  const getAllPost = async() => {
    const options = {
      method: 'GET',
      url: "https://khvw9wf1-3001.inc1.devtunnels.ms/post/getPost",
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    
    try {
      const { data } = await axios.request(options);
      console.log("data..............",data);

      setPosts(data.post)
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <>
      <div id="content-page" className="content-inner">
        {/* <FsLightbox
          toggler={imageController.toggler}
          sources={[user5, boyImg, busImg, img11, mountain, pizza]}
          slide={imageController.slide}
        /> */}
        <Container>
          <Row className="gx-4">
            <Col lg={8}>
              <div id="content">
                <Row>
                  <Col sm={12}>
                    <div className="mb-5">
                      {/* <Stories stories={stories} /> */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <CreatePost 
                    className="card-block card-stretch card-height"
                    onPostCreated={() => {
                      console.log("Post created successfully!");
                      getAllPost()
                      // Add any other actions after post creation
                    }} 
                    />
                  </Col>
                </Row>


               
                    {allPost?.map((items, i) => (
                      // <p>hello world</p>
                      <Post items={items} key={i}/>
                     
                    ))}
              </div>
            </Col>

           
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Index;
